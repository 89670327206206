
import styled from "styled-components";
import * as yup from "yup";
import { router, usePage } from "@inertiajs/react";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import logo from "@/assets/LogoFooter.png";
import logowebp from "@/assets/LogoFooter.webp";
import loginImage from "@/assets/resgisterimage.png";
import loginImagewebp from "@/assets/resgisterimage.webp";
import VisibilityOn from "@/assets/visibility.svg";
import VisibilityOff from "@/assets/visibility_off.svg";
import { FiX } from "react-icons/fi";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import toast from "react-hot-toast";
import { motion } from "framer-motion";
import { CircularProgress } from "@mui/material";
import logofooter2webp from "@/assets/LogoFooter2.webp";
import logofooter2 from "@/assets/LogoFooter2.png";
import WebPImage from "@/components/WebPImage";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Button } from "@nextui-org/react";


type FormData = {
    email: string;
    password: string;
};



type LoginProps = {
    closeModal: () => void;
    openRegisterModal: () => void;
    openForgotPasswordModal: () => void;
};

const StyledLogin = styled.div`
    background-repeat: no-repeat;
    background-size: cover;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-text-fill-color: white !important;
        transition: background-color 5000s ease-in-out 0s;
    }
`;

const Login: React.FC<LoginProps> = ({
    closeModal,
    openRegisterModal,
    openForgotPasswordModal,
}) => {

    const { t } = useTranslation(["common", "validation", "glossary"]);
    const { executeRecaptcha } = useGoogleReCaptcha()
    const loginSchema = yup
        .object({
            email: yup.string().required(t('validation:required-email')).email(t('validation:error-email')),
            password: yup.string().required(t('validation:error-password')).min(6, t('validation:type-password', { min: 6 })),
        })
        .required();

    const loading = useSelector((state: RootState) => state.plan.loading)

    const selectedItem = useSelector(
        (state: RootState) => state.modal.selectedItem,
    );
    const { locale } = usePage().props;
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({ resolver: yupResolver(loginSchema) });

    const currentRoute = useSelector(
        (state: RootState) => state.modal.currentRoute,
    )


    const onSubmit = useCallback(
        (data: FormData) => {

            if (!executeRecaptcha) {
                console.log("Execute recaptcha not yet available");
                return;
            }
            executeRecaptcha("form").then((gReCaptchaToken: string) => {
                submitForm(gReCaptchaToken, data);
            });
        },
        [executeRecaptcha]
    );


    const submitForm = (gReCaptchaToken: string, data: FormData) => {
        //if click on wishlist show login modal and when logins redirect at /wishlist
        if (currentRoute !== null) {
            data.route = currentRoute;
        }
        router.post(`/${locale}/login`,
            {
                ...data,
                "g-recaptcha-response": gReCaptchaToken
            },
            {
                onSuccess: () => {
                    toast.success(t("login_success", { ns: "validation" }));
                    closeModal();
                    if (selectedItem !== undefined) {
                        router.patch(
                            `/${locale}/wishable/add`,
                            {
                                id: selectedItem.id,
                                type: selectedItem.type,
                            },
                            {
                                preserveScroll: true,
                                onSuccess: () => {
                                    selectedItem.isOnWishlist = true;
                                },
                            },
                        );
                    }
                },
            });
    }

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <StyledLogin>
            <div className="h-screen flex flex-row-reverse bg-[#070F2B]">
                <div
                    className="xl:w-1/2 w-full h-full flex items-center justify-center relative"
                    style={{
                        backgroundImage: `url(${loginImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center right",
                    }}
                >
                    {/* Black layer with 40% opacity */}
                    <div className="absolute top-0 left-0 w-full h-full bg-black opacity-40 z-0"></div>
                    <button
                        className="absolute xl:block hidden text-white top-12 right-12 z-10"
                        onClick={closeModal}
                    >
                        <FiX size={40} />
                    </button>
                    <button
                        className="absolute block xl:hidden text-white top-6 right-6 z-10"
                        onClick={closeModal}
                    >
                        <FiX size={34} />
                    </button>
                    <div className="absolute top-0 left-0 w-full h-full pointer-events-none xl:bg-gradient-to-r from-[#070F2B] z-10"></div>
                    <div className="p-8 rounded-lg xl:max-w-xl md:max-w-2xl w-full relative z-20">
                        <div className="xl:hidden flex justify-center mb-8">
                            <WebPImage
                                srcWebP={logowebp}
                                srcFallback={logo}
                                alt="Thassos Greece"
                                className="h-16 mb-8"
                            />
                        </div>

                        <h1 className="text-white font-bold text-3xl mb-4 text-end">
                            {t("login", { ns: "glossary" })}
                        </h1>
                        <p className="text-white font-extrathin text-lg mb-8 text-end">
                            {t("not_member", { ns: "glossary" })}{" "}
                            <span
                                className="ml-1 underline font-bold cursor-pointer"
                                onClick={() => {
                                    closeModal();
                                    openRegisterModal();
                                }}
                            >
                                {t("register", { ns: "glossary" })}
                            </span>
                        </p>

                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                            <div>
                                <input
                                    id="email"
                                    type="email"
                                    placeholder="email *"
                                    {...register("email")}
                                    className="p-3 w-full bg-[#6d7079] rounded-[15px] backdrop-blur-[50px] text-white placeholder-white"
                                />
                                {errors.email && (
                                    <p className="text-red-500 text-sm">
                                        {errors.email?.message}
                                    </p>
                                )}
                            </div>

                            <div className="relative">
                                <input
                                    id="password"
                                    type={passwordVisible ? "text" : "password"}
                                    placeholder={t("password", {
                                        ns: "glossary",
                                    })}
                                    {...register("password")}
                                    className="p-3 w-full bg-[#6d7079] rounded-[15px] backdrop-blur-[50px] text-white placeholder-white"
                                />
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className="absolute inset-y-0 right-0 px-3 text-white"
                                >
                                    <img
                                        src={
                                            passwordVisible
                                                ? VisibilityOff
                                                : VisibilityOn
                                        }
                                        alt="Toggle visibility"
                                        style={{ filter: "invert(100%)" }}
                                    />
                                </button>
                                {errors.password && (
                                    <p className="text-red-500 text-sm">
                                        {errors.password?.message}
                                    </p>
                                )}
                            </div>

                            <div className="flex flex-col items-center md:flex-row justify-between">
                                <span
                                    className="underline text-white mb-4 mx-auto md:mb-0 md:mx-0 cursor-pointer"
                                    onClick={() => {
                                        closeModal();
                                        openForgotPasswordModal();
                                    }}
                                >
                                    {t("forgot_password", {
                                        ns: "glossary",
                                    })}
                                </span>
                                <Button
                                    isLoading={loading}
                                    disabled={loading}
                                    title={t("login", { ns: "glossary" })}
                                    type="submit"
                                    className="w-full md:w-2/5 py-7 px-4 text-white text-lg font-medium bg-[#1D24CA] rounded-[10px] hover:bg-blue-700"
                                >
                                    {t("login", { ns: "glossary" })}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="w-1/2 h-full bg-[#070F2B] hidden xl:flex items-center justify-center">
                    <WebPImage
                        className="h-30"
                        srcWebP={logowebp}
                        srcFallback={logo}
                        alt="Thassos Greece"
                    />
                </div>
            </div>
        </StyledLogin>
    );
};

export default Login;
